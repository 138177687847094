import React, { Component } from "react";
// import axios from "axios";
import {SITE} from './../constants';
import $ from 'jquery';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuccessSent = this.handleSuccessSent.bind(this);

    this.state = {
      formMsg: ""
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    var obj = {};
    obj.fullName = this.fullName.value;
    obj.email = this.email.value;
    obj.msg = this.msg.value;

    if (obj.fullName == "" || obj.email == "" || obj.msg == "") {
      var formMsg = "Трябва да попълниш всички полета";

      this.setState({
        formMsg: formMsg,
        formMsgClass: "alert alert-danger"
      });
    } else {

        $.ajax({
            url: `${SITE}send_email.php`,
            type: "post",
            data: obj ,
            success: this.handleSuccessSent,
            error: function(jqXHR, textStatus, errorThrown) {
               console.log(textStatus, errorThrown);
            }
    
    
        });
    }
  }

  handleSuccessSent(response) {
    console.log(response == 1)
    if (response == 1) {
        var formMsg = "Успешно изпратено запитване";
        this.setState({
          formMsg: formMsg,
          formMsgClass: "alert alert-success"
        });
        this.fullName.value = "";
        this.email.value = "";
        this.msg.value = "";
      }    
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className={this.state.formMsgClass}>{this.state.formMsg}</div>
          </div>
          <div className="col-sm-6 form-group">
            <input
              ref={fullName => (this.fullName = fullName)}
              className="form-control"
              id="name"
              name="name"
              placeholder="Вашите имена"
              type="text"
              required
            />
          </div>
          <div className="col-sm-6 form-group">
            <input
              ref={email => (this.email = email)}
              className="form-control"
              id="email"
              name="email"
              placeholder="Вашият имейл"
              type="email"
              required
            />
          </div>
        </div>
        <textarea
          ref={msg => (this.msg = msg)}
          className="form-control"
          id="comments"
          name="comments"
          placeholder="Вашето съобщение"
          rows="5"
        />
        <br />
        <div className="row">
          <div className="col-sm-12 form-group">
            <button
              className="btn btn-default pull-right"
              type="submit"
              onClick={this.handleSubmit}
            >
              Изпрати
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
